import { Button, Col, Row, Statistic } from 'antd';
import React from 'react';

import Layout from '../../components/Layout';

import { Container } from './styles';

const Dashboard: React.FC = () => {
  return (
    <Layout title="Dashboard">
      <Container>
        <h1>Informações</h1>

        <Row className="chart" gutter={16}>
          <Col span={12}>
            <Statistic title="Usuários ativos" value={Number('000000')} />
          </Col>
          <Col span={12}>
            <Statistic title="Account Balance (BRL)" value={10} precision={2} />
            <Button style={{ marginTop: 16 }} type="primary">
              Atualizar
            </Button>
          </Col>
          <Col span={12}>
            <Statistic title="Usuários ativos" value={Number('0000')} loading />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Dashboard;
