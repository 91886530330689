import { Button, Divider, Input, message, Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdAdd, MdClose } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import api from '../../../services/api';

import { Container, AddForm } from './styles';

interface Category {
  _id: string;
  name: string;
}

interface Subcategory {
  _id: string;
  name: string;
  description: string;
  category: Category;
}

const SubcategoriesForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [subcategory, setSubcategory] = useState<Subcategory>(
    {} as Subcategory,
  );
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const nameRef = useRef<Input>(null);
  const descriptionRef = useRef<TextArea>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const categoriesResponse = await api.get('categories');

        setCategories(categoriesResponse.data);

        if (id !== 'add') {
          setIsUpdating(true);
          const response = await api.get(`subcategories/${id}`);

          setSubcategory(response.data);
          setCategory(response.data.category);
        }
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id]);

  const handleAddSubcategory = useCallback(() => {
    setLoading(true);

    api
      .post('subcategories', {
        name: nameRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        category,
      })
      .then(() => {
        message.success('Subcategoria criada com sucesso');
        history.push('/subcategories');
      })
      .catch(() => message.error('Erro ao criar categoria'))
      .finally(() => setLoading(false));
  }, [category, history]);

  const handleUpdateSubcategory = useCallback(() => {
    setLoading(true);

    api
      .put(`subcategories/${id}`, {
        name: nameRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        category,
      })
      .then(() => {
        message.success('Subcategoria atualizada com sucesso');
        history.push('/subcategories');
      })
      .catch(() => message.error('Erro ao atualizar categoria'))
      .finally(() => setLoading(false));
  }, [category, history, id]);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    if (!category) {
      message.warning('Escolha uma categoria');
      return;
    }

    if (isUpdating) {
      handleUpdateSubcategory();
    } else {
      handleAddSubcategory();
    }
  };

  if (loading) {
    return (
      <Layout title="Categorias">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Subcategorias">
      <Container>
        <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>

        <AddForm onSubmit={handleSubmit}>
          <div className="input-group">
            <h4>Nome</h4>
            <Input
              ref={nameRef}
              type="text"
              defaultValue={subcategory.name}
              required
            />
          </div>

          <div className="input-group">
            <h4>Categoria</h4>
            <Select defaultValue={category} onChange={e => setCategory(e)}>
              {categories.map(categoryItem => (
                <Select.Option key={categoryItem._id} value={categoryItem._id}>
                  {categoryItem.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="inputs-group">
            <div className="input-group">
              <h4>Descrição</h4>
              <TextArea
                ref={descriptionRef}
                defaultValue={subcategory.description}
              />
            </div>
          </div>

          <Divider />

          <div className="button-group">
            <Button
              icon={<MdClose />}
              type="primary"
              danger
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
            <Button icon={<MdAdd />} type="primary" htmlType="submit">
              {isUpdating ? 'Atualizar' : 'Adicionar'}
            </Button>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default SubcategoriesForm;
