import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Divider, Image, Input, message, Select, Spin } from 'antd';
import { MdAdd, MdClose } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import { getYouTubeId } from '../../../utils/getVideoID';

import Layout from '../../../components/Layout';

import { Container, AddForm, Preview } from './styles';
import api from '../../../services/api';

interface Category {
  _id: string;
  name: string;
}

interface Subcategory {
  _id: string;
  name: string;
  category: string;
}

interface Video {
  _id: string;
  title: string;
  category: Category;
  subcategory: Subcategory;
  videoUrl: string;
  description: string;
}

const VideoForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [video, setVideo] = useState<Video>({} as Video);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [linkUrl, setLinkUrl] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');

  const titleRef = useRef<Input>(null);
  const urlRef = useRef<Input>(null);
  const descriptionRef = useRef<TextArea>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('categories');

        setCategories(response.data);

        const subcategoriesResponse = await api.get('subcategories');

        setSubcategories(subcategoriesResponse.data);

        if (id !== 'add') {
          setIsUpdating(true);
          const videoResponse = await api.get(`videos/${id}`);

          setVideo(videoResponse.data);
          setCategory(videoResponse.data.category);
          setSubcategory(videoResponse.data.subcategory);
          setLinkUrl(videoResponse.data.videoUrl);

          if (videoResponse.data.category) {
            await handleLoadSubCategories(videoResponse.data.category);
          }
        }
      } catch (err) {
        message.error('Falha ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id]);

  const handleAddVideo = useCallback(() => {
    api
      .post('videos', {
        title: titleRef.current?.input.value,
        videoUrl: urlRef.current?.input.value,
        category,
        subcategory,
        description: descriptionRef.current?.state.value,
      })
      .then(() => {
        message.success('Video criado com sucesso');
        history.push('/videos');
      })
      .catch(() => message.error('Erro ao criar video'))
      .finally(() => setLoading(false));
  }, [category, history, subcategory]);

  const handleUpdateVideo = useCallback(() => {
    api
      .put(`videos/${id}`, {
        title: titleRef.current?.input.value,
        videoUrl: urlRef.current?.input.value,
        category,
        subcategory,
        description: descriptionRef.current?.state.value,
      })
      .then(() => {
        message.success('Video atualizado com sucesso');
        history.push('/videos');
      })
      .catch(() => message.error('Erro ao atualizar video'))
      .finally(() => setLoading(false));
  }, [category, history, id, subcategory]);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    if (!category) {
      message.warning('Selecione uma categoria');
      return;
    }

    if (isUpdating) {
      handleUpdateVideo();
    } else {
      handleAddVideo();
    }
  };

  const handleLoadSubCategories = async (categoryId: string): Promise<void> => {
    const response = await api.get(`/subcategories-category/${categoryId}`);

    setSubcategories(response.data);
  };

  const handleChangeCategory = async (value: string): Promise<void> => {
    setCategory(value);

    await handleLoadSubCategories(value);
  };

  if (loading) {
    return (
      <Layout title="Videos">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Vídeos">
      <Container>
        <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>

        <AddForm onSubmit={handleSubmit}>
          <div className="input-group">
            <h4>Título</h4>
            <Input
              ref={titleRef}
              defaultValue={video.title}
              type="text"
              required
            />
          </div>

          <div className="input-group">
            <h4>Link do vídeo</h4>
            <Input
              ref={urlRef}
              value={linkUrl}
              onChange={e => setLinkUrl(e.target.value)}
              type="text"
              required
            />
          </div>

          <div className="inputs-group">
            <div className="input-group">
              <h4>Categoria</h4>
              <Select defaultValue={category} onChange={handleChangeCategory}>
                {categories.map(categoryItem => (
                  <Select.Option
                    key={categoryItem._id}
                    value={categoryItem._id}
                  >
                    {categoryItem.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="input-group">
              <h4>Subcategoria</h4>
              <Select
                defaultValue={subcategory}
                onChange={e => setSubcategory(e)}
              >
                {subcategories.map(subcategoryItem => (
                  <Select.Option
                    key={subcategoryItem._id}
                    value={subcategoryItem._id}
                  >
                    {subcategoryItem.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="input-group">
            <h4>Descrição/Instruções</h4>
            <TextArea
              ref={descriptionRef}
              defaultValue={video.description}
              rows={3}
              required
            />
          </div>

          <Divider />

          <Preview>
            <h3>Preview</h3>

            <Image
              src={`https://img.youtube.com/vi/${getYouTubeId(linkUrl)}/0.jpg`}
            />
          </Preview>

          <Divider />

          <div className="button-group">
            <Button
              icon={<MdClose />}
              type="primary"
              danger
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
            <Button icon={<MdAdd />} type="primary" htmlType="submit">
              {isUpdating ? 'Atualizar' : 'Adicionar'}
            </Button>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default VideoForm;
