import { Button, Card, Input, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';

import { Container } from './styles';

import api from '../../services/api';

interface Training {
  _id: string;
  description: string;
  name: string;
}

const Training: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [trainings, setTrainings] = useState<Training[]>([]);
  const [trainingsList, setTrainingList] = useState<Training[]>([]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('training');
        setTrainings(response.data);
        setTrainingList(response.data);
      } catch (error) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Treinos">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  function handleSearchTrainings(value: string) {
    const search = trainings.filter(
      (i: Training) => i.name.toUpperCase().indexOf(value.toUpperCase()) > -1,
    );
    setTrainingList(search);
  }

  return (
    <Layout title="Treinos">
      <Container>
        <h2>Gerenciamento de Treinos</h2>

        <div id="controls">
          <Button
            icon={<MdAdd />}
            size="large"
            onClick={() => history.push('/training/add')}
          >
            Adicionar
          </Button>

          <Input
            placeholder="Pesquisar"
            size="large"
            style={{ maxWidth: 290 }}
            onChange={(e: any) => handleSearchTrainings(e.target.value)}
          />
        </div>

        {trainingsList.map(training => (
          <Card
            title={training.name}
            hoverable
            onClick={() => history.push(`training/${training._id}`)}
            style={{ width: 300, marginTop: 20 }}
          >
            <p>{training.description}</p>
          </Card>
        ))}
      </Container>
    </Layout>
  );
};

export default Training;
