import React from 'react';
import {
  MdDashboard,
  MdVideoLibrary,
  MdDehaze,
  MdPerson,
  MdPanTool,
} from 'react-icons/md';
import { CgGym, CgLogOut } from 'react-icons/cg';
import { IoMdPin } from 'react-icons/io';
import { BsCollectionFill } from 'react-icons/bs';
import logoImg from '../../assets/images/logo-extended.png';
import logoImgIcon from '../../assets/images/logo-icon.png';

import { Container, MenuItem, MenuItemButton } from './styles';
import { useAuth } from '../../hooks/auth';
import { useSideMenu } from '../../hooks/sidemenu';

const SideMenu: React.FC = () => {
  const { signOut } = useAuth();
  const { isDrawerOpen } = useSideMenu();

  const handleLogOut = (): void => {
    signOut();
  };

  return (
    <Container $isOpen={isDrawerOpen}>
      <img src={isDrawerOpen ? logoImg : logoImgIcon} alt="CyberFit" />

      <div className="line" />

      {/* <Tooltip placement="right" title="Dashboard" color="#7995f3" > */}
      <MenuItem $isOpen={isDrawerOpen} to="/dashboard">
        <MdDashboard />
        <span>Dashboard</span>
      </MenuItem>
      {/* </Tooltip> */}

      <MenuItem $isOpen={isDrawerOpen} to="/users">
        <MdPerson />
        <span>Usuários</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/entities">
        <IoMdPin />
        <span>Entidades</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/categories">
        <MdDehaze />
        <span>Categorias</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/subcategories">
        <MdDehaze />
        <span>Subcategorias</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/videos">
        <MdVideoLibrary />
        <span>Vídeos</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/equipments">
        <MdPanTool />
        <span>Equipamentos</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/exercises">
        <CgGym />
        <span>Exercícios</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/training">
        <BsCollectionFill />
        <span>Treinos</span>
      </MenuItem>

      <MenuItemButton $isOpen={isDrawerOpen} onClick={handleLogOut}>
        <CgLogOut />
        <span>Sair</span>
      </MenuItemButton>
    </Container>
  );
};

export default SideMenu;
