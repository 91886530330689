import { Button, Container, lighten } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerDiv = styled(Container)`
  display: flex;
  flex-direction: column;
`;

export const MainDiv = styled.div`
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    width: 250px;
  }

  form {
    width: 100%;
    margin-top: 10px;
  }
`;

export const ButtonBlue = styled(Button)`
  && {
    background-color: #150a6a;
    margin: 5px 0 10px;
    color: #fff;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;

    &:hover {
      background-color: ${lighten('#150a6a', 0.2)};
    }
  }
`;
