import React, { FormEvent, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Card,
  Divider,
  Input,
  List,
  message,
  Modal,
  Select,
  Spin,
  TimePicker,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line prettier/prettier
import {
  MdAdd,
  MdClose,
  MdDelete,
  MdFilterList,
  MdSearch,
} from 'react-icons/md';
import { CgGym } from 'react-icons/cg';

import Layout from '../../../components/Layout';

import { AddForm, Container } from './styles';
import api from '../../../services/api';
import { getYouTubeId } from '../../../utils/getVideoID';

interface Category {
  _id: string;
  name: string;
}

interface Subcategory {
  _id: string;
  name: string;
  category: string;
}

interface Video {
  _id: string;
  title: string;
  category: string;
  subcategory: string;
  videoUrl: string;
  description: string;
}

interface Exercise {
  name: string;
  description: string;

  series: number;
  repetitions: number;
  interval: string;
  duration?: string;
  load?: number;

  video: string;
  category: string;
  subcategory: string;

  alternativeExercise?: string;
}

interface Exercises {
  _id: string;
  name: string;
  description: string;
  video?: {
    _id: string;
    videoUrl: string;
  };
}

const ExercisesForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [timeInterval, setTimeInterval] = useState('');
  const [alternativeExercise, setAlternativeExercise] = useState('');
  const [duration, setDuration] = useState('');
  const [videoCategory, setVideoCategory] = useState('');
  const [videoSubcategory, setVideoSubcategory] = useState('');
  const [exercise, setExercise] = useState<Exercise>({} as Exercise);
  const [exercises, setExercises] = useState<Exercises[]>([]);
  const [isSectorModalVisible, setIsSectorModalVisible] = useState(false);
  const [videos, setVideos] = useState<Video[]>([]);
  const [videosList, setVideosList] = useState<Video[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<Video>({} as Video);
  const [selectedExercise, setSelectedExercise] = useState<Exercises>(
    {} as Exercises,
  );

  const [
    selectedVideoAlternativeExercise,
    setSelectedVideoAlternativeExercise,
  ] = useState<Video>({} as Video);

  const nameRef = useRef<Input>(null);
  const descriptionRef = useRef<TextArea>(null);
  const seriesRef = useRef<Input>(null);
  const repetitionsRef = useRef<Input>(null);
  const loadRef = useRef<Input>(null);
  const intervalRef = useRef<Input>(null);
  const durationRef = useRef<Input>(null);
  const videoNameRef = useRef<Input>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('categories');

        setCategories(response.data);

        const subcategoriesResponse = await api.get('subcategories');

        setSubcategories(subcategoriesResponse.data);

        if (id !== 'add') {
          setIsUpdating(true);
          const exerciseResponse = await api.get(`exercises/${id}`);

          setExercise(exerciseResponse.data);
          setCategory(exerciseResponse.data.category);
          setSubcategory(exerciseResponse.data.subcategory);
          setTimeInterval(exerciseResponse.data.interval);
          setDuration(exerciseResponse.data.duration);
          await loadVideo(exerciseResponse.data.video);
          await loadAlternativeExercise(
            exerciseResponse.data.alternativeExercise,
          );

          if (exerciseResponse.data.category) {
            await handleLoadSubCategories(exerciseResponse.data.category);
          }
        }
      } catch (err) {
        message.error('Falha ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id]);

  const handleAddExercise = (): void => {
    api
      .post('exercises', {
        name: nameRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        series: seriesRef.current?.input.value,
        repetitions: repetitionsRef.current?.input.value,
        interval: timeInterval,
        duration,
        load: loadRef.current?.input.value,
        video: selectedVideo._id,
        category,
        subcategory,
        alternativeExercise,
      })
      .then(() => {
        message.success('Exercício criado com sucesso');
        history.push('/exercises');
      })
      .catch(() => message.error('Erro ao criar exercício'))
      .finally(() => setLoading(false));
  };

  const handleUpdateExercise = (): void => {
    api
      .put(`exercises/${id}`, {
        name: nameRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        series: seriesRef.current?.input.value,
        repetitions: repetitionsRef.current?.input.value,
        interval: timeInterval,
        duration,
        load: loadRef.current?.input.value,
        video: selectedVideo._id,
        category,
        subcategory,
        alternativeExercise,
      })
      .then(() => {
        message.success('Exercício atualizado com sucesso');
        history.push('/exercises');
      })
      .catch(() => message.error('Erro ao atualizar exercício'))
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    if (!category || !subcategory) {
      message.warning('Selecione uma categoria e uma subcategoria');
      return;
    }

    if (isUpdating) {
      handleUpdateExercise();
    } else {
      handleAddExercise();
    }
  };

  async function handleDeleteExercice() {
    try {
      await api.delete(`/exercises/${id}`);

      history.push('/exercises');
      message.success('Exercicio excluído');
    } catch (error) {
      message.error('Erro ao excluir exercício');
    }
  }

  const handleRemoveAlternativeExercise = () => {
    setExercise({ ...exercise, alternativeExercise: '' });
    setSelectedExercise({} as Exercises);
    setAlternativeExercise('');
    setSelectedVideoAlternativeExercise({} as Video);
  };

  const handleLoadSubCategories = async (categoryId: string): Promise<void> => {
    const response = await api.get(`/subcategories-category/${categoryId}`);

    setSubcategories(response.data);
  };

  const handleChangeCategory = async (value: string): Promise<void> => {
    setCategory(value);

    await handleLoadSubCategories(value);
  };

  const handleChangeVideoCategory = async (value: string): Promise<void> => {
    setVideoCategory(value);

    await handleLoadSubCategories(value);
  };

  const handleSearchVideos = async (): Promise<void> => {
    const videosResponse = await api.get('videos-search', {
      params: {
        name: videoNameRef.current?.input.value,
        categoryId: videoCategory,
        subcategoryId: videoSubcategory,
      },
    });

    setVideos(videosResponse.data);
    setVideosList(videosResponse.data);
    setSelectedVideo({} as Video);
    setIsSectorModalVisible(false);
  };

  async function handleSearchExercises(): Promise<void> {
    const response = await api.get('exercises');

    setExercises(response.data);
  }

  const loadVideo = async (videoId: string): Promise<void> => {
    if (!videoId) {
      return;
    }
    const videoResponse = await api.get(`videos/${videoId}`);

    setSelectedVideo(videoResponse.data);
  };

  const loadVideoAlternativeExercise = async (
    videoId: string,
  ): Promise<void> => {
    if (!videoId) {
      return;
    }

    const videoResponse = await api.get(`videos/${videoId}`);

    setSelectedVideoAlternativeExercise(videoResponse.data);
  };

  const loadAlternativeExercise = async (trainingId: string): Promise<void> => {
    if (!trainingId) {
      return;
    }

    const trainingResponse = await api.get(`/exercises/${trainingId}`);

    setSelectedExercise(trainingResponse.data);
    setAlternativeExercise(trainingResponse.data);

    await loadVideoAlternativeExercise(trainingResponse.data.video);
  };

  const handleSelectVideo = (video: Video): void => {
    setSelectedVideo(video);
    setCategory(video.category);
    setSubcategory(video.subcategory);
    setVideos([]);
  };

  const handleSelectExercise = (training: Exercises): void => {
    setAlternativeExercise(training._id);
    setSelectedExercise(training);
    setExercises([]);
  };

  function handleSeach(value: string) {
    const search = videos.filter(
      (i: Video) => i.title.toUpperCase().indexOf(value.toUpperCase()) > -1,
    );
    setVideosList(search);
  }

  if (loading) {
    return (
      <Layout title="Exercícios">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }
  return (
    <Layout title="Exercícios">
      <Container>
        <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>

        <AddForm onSubmit={handleSubmit}>
          <div className="input-group">
            <h4>Nome</h4>
            <Input
              ref={nameRef}
              defaultValue={exercise.name}
              type="text"
              required
            />
          </div>

          <div className="input-group">
            <h4>Instruções/Descrição</h4>
            <TextArea
              ref={descriptionRef}
              defaultValue={exercise.description}
              rows={3}
              required
            />
          </div>

          <div className="inputs-group">
            <div className="input-group">
              <h4>Num Séries</h4>
              <Input
                ref={seriesRef}
                defaultValue={exercise.series}
                type="number"
                required
              />
            </div>
            <div className="input-group">
              <h4>Num Repetições</h4>
              <Input
                ref={repetitionsRef}
                defaultValue={exercise.repetitions}
                type="number"
                required
              />
            </div>
            <div className="input-group">
              <h4>Intervalo/Descanso</h4>
              <TimePicker
                ref={intervalRef}
                value={
                  timeInterval ? moment(timeInterval, 'HH:mm:ss') : undefined
                }
                onChange={(_, timeString) => setTimeInterval(timeString)}
                placeholder="Selecione tempo"
              />
            </div>
            <div className="input-group">
              <h4>Duração</h4>
              <TimePicker
                ref={durationRef}
                value={duration ? moment(duration, 'HH:mm:ss') : undefined}
                onChange={(_, timeString) => setDuration(timeString)}
                placeholder="Selecione tempo"
              />
            </div>
            <div className="input-group">
              <h4>Carga</h4>
              <Input
                ref={loadRef}
                defaultValue={exercise.load}
                type="number"
                suffix={<span>Kg</span>}
              />
            </div>
          </div>

          <div className="inputs-group">
            <div className="input-group">
              <h4>Categoria</h4>
              <Select value={category} onChange={handleChangeCategory}>
                {categories.map(categoryItem => (
                  <Select.Option
                    key={categoryItem._id}
                    value={categoryItem._id}
                  >
                    {categoryItem.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="input-group">
              <h4>Subcategoria</h4>
              <Select value={subcategory} onChange={e => setSubcategory(e)}>
                {subcategories.map(subcategoryItem => (
                  <Select.Option
                    key={subcategoryItem._id}
                    value={subcategoryItem._id}
                  >
                    {subcategoryItem.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <Divider />

          <div className="form-group">
            <div>
              <h3>Vídeo(Opcional)</h3>
              <Button
                icon={<MdFilterList />}
                onClick={() => setIsSectorModalVisible(true)}
              >
                Filtros
              </Button>
            </div>
            <Modal
              // eslint-disable-next-line prettier/prettier
              title={
                <>
                  <h3>Pesquisar vídeos</h3>
                  <span>Deixe em branco para ver todos</span>
                </>
                // eslint-disable-next-line prettier/prettier
              }
              centered
              visible={isSectorModalVisible}
              onCancel={() => {
                setIsSectorModalVisible(false);
                setVideoCategory('');
                setVideoSubcategory('');
              }}
              footer={[
                <Button
                  key="back"
                  onClick={() => {
                    setIsSectorModalVisible(false);
                    setVideoCategory('');
                    setVideoSubcategory('');
                  }}
                >
                  Cancelar
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleSearchVideos}
                >
                  Aplicar
                </Button>,
              ]}
            >
              <div className="inputs-group">
                <div className="input-group">
                  <h4>Titulo</h4>
                  <Input ref={videoNameRef} type="text" />
                </div>
                <div className="input-group">
                  <h4>Categoria</h4>
                  <Select
                    style={{ width: '100%' }}
                    value={videoCategory}
                    onChange={handleChangeVideoCategory}
                  >
                    {categories.map(categoryItem => (
                      <Select.Option
                        key={categoryItem._id}
                        value={categoryItem._id}
                      >
                        {categoryItem.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="input-group">
                  <h4>Subcategoria</h4>
                  <Select
                    style={{ width: '100%' }}
                    value={videoSubcategory}
                    onChange={e => setVideoSubcategory(e)}
                  >
                    {subcategories.map(subcategoryItem => (
                      <Select.Option
                        key={subcategoryItem._id}
                        value={subcategoryItem._id}
                      >
                        {subcategoryItem.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Modal>

            {selectedVideo.videoUrl && (
              <Card
                hoverable
                style={{
                  width: 240,
                  marginTop: 20,
                  border: '2px solid #3369cc',
                }}
                // eslint-disable-next-line prettier/prettier
                cover={
                  <img
                    alt={selectedVideo.title}
                    src={`https://img.youtube.com/vi/${getYouTubeId(
                      selectedVideo.videoUrl || '',
                    )}/0.jpg`}
                  />
                  // eslint-disable-next-line prettier/prettier
                }
              >
                <Card.Meta
                  title={selectedVideo.title}
                  description={selectedVideo.description}
                />
              </Card>
            )}
            {videos.length > 0 && (
              <>
                <strong>Escolha:</strong>
                <Input
                  addonBefore={<MdSearch />}
                  placeholder="Pesquisar"
                  size="large"
                  onChange={(e: any) => handleSeach(e.target.value)}
                />

                <List
                  style={{ marginTop: 20 }}
                  grid={{ gutter: 20 }}
                  dataSource={videosList}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        onClick={() => handleSelectVideo(item)}
                        // eslint-disable-next-line prettier/prettier
                        cover={
                          <img
                            alt={item.title}
                            src={`https://img.youtube.com/vi/${getYouTubeId(
                              item.videoUrl,
                            )}/0.jpg`}
                          />
                          // eslint-disable-next-line prettier/prettier
                        }
                        style={{ width: 200 }}
                        hoverable
                      >
                        <Card.Meta title={item.title} />
                      </Card>
                    </List.Item>
                  )}
                />
              </>
            )}
          </div>

          <Divider />

          <div className="form-group">
            <div>
              <h3>Exercício Alternativo(Opcional)</h3>
              <Button
                icon={<MdSearch />}
                onClick={() => handleSearchExercises()}
              >
                Filtros
              </Button>

              <div className="category-grid">
                {selectedVideoAlternativeExercise.videoUrl && (
                  <Card
                    hoverable
                    style={{
                      width: 240,
                      marginTop: 20,

                      border: '2px solid #3369cc',
                    }}
                    // eslint-disable-next-line prettier/prettier
                    cover={
                      <img
                        alt={selectedVideoAlternativeExercise.title}
                        src={`https://img.youtube.com/vi/${getYouTubeId(
                          selectedVideoAlternativeExercise.videoUrl || '',
                        )}/0.jpg`}
                      />
                      // eslint-disable-next-line prettier/prettier
                    }
                  >
                    <Card.Meta
                      style={{ paddingBottom: 10 }}
                      title={selectedExercise.name}
                      description={selectedExercise.description}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                      }}
                    >
                      <Button
                        icon={<MdDelete style={{ marginTop: '3' }} />}
                        type="primary"
                        danger
                        onClick={handleRemoveAlternativeExercise}
                      >
                        {}
                      </Button>
                    </div>
                  </Card>
                )}
                {selectedExercise._id &&
                  Object.keys(selectedVideoAlternativeExercise).length ===
                    0 && (
                    <Card
                      hoverable
                      style={{
                        width: 240,
                        marginTop: 20,
                        border: '2px solid #3369cc',
                      }}
                      // eslint-disable-next-line prettier/prettier
                      cover={
                        <img
                          alt={selectedExercise.name}
                          src={`https://img.youtube.com/vi/${getYouTubeId(
                            selectedExercise.video?.videoUrl || '',
                          )}/0.jpg`}
                        />
                        // eslint-disable-next-line prettier/prettier
                      }
                    >
                      <Card.Meta
                        title={selectedExercise.name}
                        description={selectedExercise.description}
                      />
                    </Card>
                  )}
                {exercises.length > 0 && (
                  <>
                    <strong>Escolha:</strong>
                    <List
                      style={{ marginTop: 20 }}
                      grid={{ gutter: 20 }}
                      dataSource={exercises}
                      renderItem={i => (
                        <List.Item>
                          <Card
                            onClick={() => handleSelectExercise(i)}
                            cover={
                              i.video?.videoUrl ? (
                                <img
                                  alt={exercise.name}
                                  src={`https://img.youtube.com/vi/${getYouTubeId(
                                    i.video.videoUrl,
                                  )}/0.jpg`}
                                />
                              ) : (
                                <CgGym size={164} color="#a6a6a6" />
                              )
                            }
                            style={{ width: 200 }}
                            hoverable
                          >
                            <Card.Meta title={i.name} />
                          </Card>
                        </List.Item>
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <Divider />

          <div className="btn-container">
            <div className="button-deletar">
              <Button
                icon={<MdDelete />}
                type="primary"
                danger
                onClick={handleDeleteExercice}
              >
                Excluir
              </Button>
            </div>
            <div className="button-group">
              <Button
                icon={<MdClose />}
                type="primary"
                ghost
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
              <Button icon={<MdAdd />} type="primary" htmlType="submit">
                {isUpdating ? 'Atualizar' : 'Adicionar'}
              </Button>
            </div>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default ExercisesForm;
