import { ArrowLeftRounded } from '@material-ui/icons';
import { Button, Divider, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdAdd, MdClose, MdDelete } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import api from '../../../services/api';

import { Container, AddForm } from './styles';

interface IEquipment {
  _id?: string;
  name: string;
  description: string;
  videoUrl: string;
}

const EquipmentForm: React.FC = () => {
  const history = useHistory();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [equipment, setEquipment] = useState<IEquipment>({} as IEquipment);

  const { id } = useParams() as { id: string };

  useEffect(() => {
    if (id !== 'add') {
      setIsUpdating(true);
      handleSearchEquipmentById();
    }
  }, []);

  async function handleSubmit() {
    if (isUpdating) {
      handleUpdateEquipament();
      return;
    }

    handleCreateEquipment();
  }

  async function handleSearchEquipmentById() {
    try {
      const response = await api.get(`/equipments/${id}`);
      setEquipment(response.data);
    } catch (error) {
      message.error('Erro ao carregar equipamento!');
    }
  }

  async function handleCreateEquipment() {
    setIsSubmiting(true);
    try {
      await api.post('/equipments', equipment);
      history.push('/equipments');
      message.success('Equipamento criado com sucesso');
    } catch (error) {
      message.error('Erro ao criar equipamento');
    } finally {
      setIsSubmiting(false);
    }
  }

  async function handleDeleteEquipment() {
    setIsSubmiting(true);

    try {
      await api.delete(`equipments/${id}`);
      history.push('/equipments');
      message.success('Equipamento excluído');
    } catch (error) {
      message.error('Erro ao excluir equipamento');
    } finally {
      setIsSubmiting(false);
    }
  }

  async function handleUpdateEquipament() {
    setIsSubmiting(true);

    try {
      await api.put(`/equipments/${id}`, equipment);
      history.push('/equipments');
      message.success('Equipamento atualizado com sucesso');
    } catch (error) {
      message.error('Erro ao alterar dados do equipamento');
    } finally {
      setIsSubmiting(false);
    }
  }

  return (
    <Layout title="Equipamentos">
      <Container>
        <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>

        <AddForm>
          <div className="inputs-group">
            <div className="input-group">
              <h4>Nome</h4>
              <Input
                type="text"
                required
                value={equipment.name}
                onChange={e =>
                  setEquipment({ ...equipment, name: e.target.value })
                }
              />
            </div>

            <div className="input-group">
              <h4>Descrição</h4>
              <Input
                type="text"
                required
                value={equipment.description}
                onChange={e =>
                  setEquipment({ ...equipment, description: e.target.value })
                }
              />
            </div>
          </div>

          <div className="input-group">
            <h4>Vídeo</h4>
            <Input
              type="text"
              value={equipment.videoUrl}
              required
              onChange={e =>
                setEquipment({ ...equipment, videoUrl: e.target.value })
              }
            />
          </div>

          <Divider />

          <div className="btn-container">
            <div className="button-deletar">
              {isUpdating && (
                <Button
                  icon={<MdDelete />}
                  type="primary"
                  danger
                  loading={isSubmiting}
                  onClick={handleDeleteEquipment}
                >
                  Excluir
                </Button>
              )}
            </div>
            <div className="button-group">
              <Button
                icon={<ArrowLeftRounded />}
                type="primary"
                ghost
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
              <Button
                icon={<MdAdd />}
                type="primary"
                loading={isSubmiting}
                onClick={handleSubmit}
              >
                {isUpdating ? 'Atualizar' : 'Adicionar'}
              </Button>
            </div>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default EquipmentForm;
