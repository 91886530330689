import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: #ebeff2 !important;
  }

  #root {
    background-color: linear-gradient(to top, #ffffff, #eaf3fe) !important;

    .inputs-group {
      @media (max-width: 786px) {
        flex-direction: column;
      }
    }
  }

  body, input, button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
    margin: 0;
    color: #404040;
  }

  font {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }
`;
