import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ContainerProps {
  $isOpen: boolean;
}

interface MenuItemProps {
  last?: boolean;
  $isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: ${props => (props.$isOpen ? 220 : 80)}px;
  background: #fff;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  align-items: center;
  transition: 0.3s;

  > img {
    height: 50px;
    margin-bottom: 20px;
  }

  .line {
    height: 1px;
    background-color: #c4c4c4;
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const MenuItem = styled(Link)<MenuItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.$isOpen ? 'unset' : 'center')};
  width: 100%;
  padding: 10px 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 5px;

  > svg {
    width: 25px;
    height: 25px;
    color: #160c6b;
  }

  > span {
    margin-left: 15px;
    color: #160c6b;
    font-size: 16px;
    display: ${props => (props.$isOpen ? 'auto' : 'none')};
  }

  span,
  svg {
    transition: 0.2s;
  }

  &:hover {
    background-color: #7995f3;

    span,
    svg {
      color: #fff;
    }
  }
`;

export const MenuItemButton = styled.button<MenuItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.$isOpen ? 'unset' : 'center')};
  width: 100%;
  padding: 10px 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 5px;
  background: none;
  border: none;

  &:last-child {
    margin-top: auto;
    margin-bottom: 0;
    justify-content: center;
  }

  > svg {
    width: 25px;
    height: 25px;
    color: #160c6b;
  }

  > span {
    margin-left: 15px;
    color: #160c6b;
    font-size: 16px;
    display: ${props => (props.$isOpen ? 'auto' : 'none')};
  }

  span,
  svg {
    transition: 0.2s;
  }

  &:hover {
    background-color: #7995f3;

    span,
    svg {
      color: #fff;
    }
  }
`;
