import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Divider, Input, message, Select, Spin } from 'antd';
import { MdAdd, MdClose } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import MaskedInput from 'antd-mask-input';
import { format, parse, parseISO } from 'date-fns';
import Layout from '../../../components/Layout';
import states from '../../../utils/statesBR';

import { AddForm, Container } from './styles';
import api from '../../../services/api';
import IUser from '../../../interfaces/IUser';

const UsersForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [user, setUser] = useState<IUser>({} as IUser);
  const [sex, setSex] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [person, setPerson] = useState('');
  const [docType, setDocType] = useState('');
  const [docState, setDocState] = useState('');
  const [state, setState] = useState('');

  const firstNameRef = useRef<Input>(null);
  const lastNameRef = useRef<Input>(null);
  const emailRef = useRef<Input>(null);
  const birthdateRef = useRef<Input>(null);
  const cpfRef = useRef<MaskedInput>(null);
  const cnpjRef = useRef<MaskedInput>(null);
  const professionRef = useRef<Input>(null);
  const dddRef = useRef<MaskedInput>(null);
  const phoneRef = useRef<MaskedInput>(null);
  const personRef = useRef<HTMLSelectElement>(null);
  const docTypeRef = useRef<HTMLSelectElement>(null);
  const docNumberRef = useRef<MaskedInput>(null);
  const docStateRef = useRef<HTMLSelectElement>(null);
  const zipcodeRef = useRef<MaskedInput>(null);
  const streetRef = useRef<Input>(null);
  const numberRef = useRef<Input>(null);
  const complementRef = useRef<Input>(null);
  const districtRef = useRef<Input>(null);
  const cityRef = useRef<Input>(null);
  const stateRef = useRef<HTMLSelectElement>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get(`users/${id}`);

        setUser(response.data);
        setSex(response.data.sex);
        setBirthdate(format(parseISO(response.data.birthdate), 'yyyy-MM-dd'));
        setDocType(response.data.professionalData?.doc?.type);
        setDocState(response.data.professionalData?.doc?.state);
        setState(response.data.address?.state);
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    if (id !== 'add') {
      setIsUpdating(true);
      loadData();
    }
  }, [id]);

  const handleAddSubmit = useCallback(() => {
    setLoading(true);

    api
      .post('users', {
        firstName: firstNameRef.current?.input.value,
        lastName: lastNameRef.current?.input.value,
        email: emailRef.current?.input.value,
        birthdate: parse(birthdate, 'yyyy-MM-dd', new Date()).toISOString(),
        sex,
        professionalData: {
          cpf: cpfRef.current?.input.value,
          cnpj: cnpjRef.current?.input.value,
          profession: professionRef.current?.input.value,
          ddd: dddRef.current?.input.value,
          phone: phoneRef.current?.input.value,
          person,
          doc: {
            type: docType,
            id: docNumberRef.current?.input.value,
            state: docState,
          },
        },
        address: {
          zipCode: zipcodeRef.current?.input.value,
          street: streetRef.current?.input.value,
          number: numberRef.current?.input.value,
          complement: complementRef.current?.input.value,
          district: districtRef.current?.input.value,
          city: cityRef.current?.input.value,
          state,
        },
      } as IUser)
      .then(() => {
        message.success('Usuário atualizado com sucesso');
        history.push('/users');
      })
      .catch(() => message.error('Erro ao atualizar usuário'))
      .finally(() => setLoading(false));
  }, [birthdate, docState, docType, history, person, sex, state]);

  const handleUpdateSubmit = useCallback(() => {
    setLoading(true);

    api
      .put(`users/${id}`, {
        firstName: firstNameRef.current?.input.value,
        lastName: lastNameRef.current?.input.value,
        email: emailRef.current?.input.value,
        birthdate: parse(birthdate, 'yyyy-MM-dd', new Date()).toISOString(),
        sex,
        professionalData: {
          cpf: cpfRef.current?.input.value,
          cnpj: cnpjRef.current?.input.value,
          profession: professionRef.current?.input.value,
          ddd: dddRef.current?.input.value,
          phone: phoneRef.current?.input.value,
          person,
          doc: {
            type: docType,
            id: docNumberRef.current?.input.value,
            state: docState,
          },
        },
        address: {
          zipCode: zipcodeRef.current?.input.value,
          street: streetRef.current?.input.value,
          number: numberRef.current?.input.value,
          complement: complementRef.current?.input.value,
          district: districtRef.current?.input.value,
          city: cityRef.current?.input.value,
          state,
        },
      } as IUser)
      .then(() => {
        message.success('Usuário criado com sucesso');
        history.push('/users');
      })
      .catch(() => message.error('Erro ao criar usuário'))
      .finally(() => setLoading(false));
  }, [birthdate, docState, docType, history, id, person, sex, state]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (isUpdating) {
        handleUpdateSubmit();
      } else {
        handleAddSubmit();
      }
    },
    [handleAddSubmit, handleUpdateSubmit, isUpdating],
  );

  if (loading) {
    return (
      <Layout title="Usuários">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Usuários">
      <Container>
        <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>

        <AddForm onSubmit={handleSubmit}>
          <div className="form-group">
            <strong>Dados pessoais:</strong>
            <div className="inputs-group">
              <div className="input-group">
                <h4>Nome</h4>
                <Input
                  ref={firstNameRef}
                  defaultValue={user.firstName}
                  type="text"
                  required
                />
              </div>
              <div className="input-group">
                <h4>Sobrenome</h4>
                <Input
                  type="text"
                  ref={lastNameRef}
                  defaultValue={user.lastName}
                />
              </div>
            </div>
            <div className="inputs-group">
              <div className="input-group">
                <h4>Email</h4>
                <Input
                  type="email"
                  ref={emailRef}
                  defaultValue={user.email}
                  required
                />
              </div>
              <div className="input-group">
                <h4>Nascimento</h4>
                <Input
                  type="date"
                  ref={birthdateRef}
                  value={birthdate}
                  onChange={e => setBirthdate(e.target.value)}
                />
              </div>
              <div className="input-group">
                <h4>Sexo</h4>
                <Select onChange={e => setSex(e)} value={sex}>
                  <Select.Option value="M">Masculino</Select.Option>
                  <Select.Option value="F">Feminino</Select.Option>
                </Select>
              </div>
            </div>
          </div>

          <Divider />

          <div className="form-group">
            <strong>Dados profissionais:</strong>
            <div className="inputs-group">
              <div className="input-group">
                <h4>CPF</h4>
                <MaskedInput
                  mask="111.111.111-11"
                  type="text"
                  ref={cpfRef}
                  defaultValue={user.professionalData?.cpf}
                />
              </div>
              <div className="input-group">
                <h4>CNPJ</h4>
                <MaskedInput
                  mask="11.111.111/1111-11"
                  type="text"
                  ref={cnpjRef}
                  defaultValue={user.professionalData?.cnpj}
                />
              </div>
              <div className="input-group">
                <h4>Profissão</h4>
                <Input
                  type="text"
                  ref={professionRef}
                  defaultValue={user.professionalData?.profession}
                />
              </div>
            </div>
            <div className="inputs-group">
              <div className="input-group w-20">
                <h4>DDD</h4>
                <MaskedInput
                  mask="11"
                  type="text"
                  ref={dddRef}
                  defaultValue={user.professionalData?.ddd}
                />
              </div>
              <div className="input-group">
                <h4>Telefone</h4>
                <MaskedInput
                  mask="11111-1111"
                  ref={phoneRef}
                  type="text"
                  defaultValue={user.professionalData?.phone}
                />
              </div>
              <div className="input-group">
                <h4>Pessoa</h4>
                <Select
                  ref={personRef}
                  onChange={e => setPerson(e)}
                  defaultValue={user.professionalData?.person || 'F'}
                >
                  <Select.Option value="F">Física</Select.Option>
                  <Select.Option value="J">Jurídica</Select.Option>
                </Select>
              </div>
            </div>
            <div className="inputs-group">
              <div className="input-group">
                <h4>Tipo documento</h4>
                <Select
                  ref={docTypeRef}
                  onChange={e => setDocType(e)}
                  defaultValue={user.professionalData?.doc?.type}
                >
                  <Select.Option value="RG">RG</Select.Option>
                  <Select.Option value="CNH">CNH</Select.Option>
                </Select>
              </div>
              <div className="input-group">
                <h4>Número</h4>
                {docType === 'RG' ? (
                  <MaskedInput
                    mask="11.111.111-1"
                    ref={docNumberRef}
                    defaultValue={user.professionalData?.doc?.id}
                    type="text"
                  />
                ) : (
                  <MaskedInput
                    mask="11111111111"
                    ref={docNumberRef}
                    defaultValue={user.professionalData?.doc?.id}
                    type="text"
                  />
                )}
              </div>
              <div className="input-group">
                <h4>Estado Emissor</h4>
                <Select
                  ref={docStateRef}
                  onChange={e => setDocState(e)}
                  defaultValue={user.professionalData?.doc?.state}
                >
                  {states.map(stateObj => (
                    <Select.Option key={stateObj.key} value={stateObj.key}>
                      {stateObj.value}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          <Divider />

          <div className="form-group">
            <strong>Endereço:</strong>
            <div className="inputs-group">
              <div className="input-group">
                <h4>CEP</h4>
                <MaskedInput
                  mask="11111-111"
                  ref={zipcodeRef}
                  defaultValue={user.address?.zipCode}
                  type="text"
                />
              </div>
              <div className="input-group">
                <h4>Rua</h4>
                <Input
                  type="text"
                  ref={streetRef}
                  defaultValue={user.address?.street}
                />
              </div>
              <div className="input-group">
                <h4>Número</h4>
                <Input
                  type="text"
                  ref={numberRef}
                  defaultValue={user.address?.number}
                />
              </div>
            </div>
            <div className="inputs-group">
              <div className="input-group">
                <h4>Complemento</h4>
                <Input
                  type="text"
                  ref={complementRef}
                  defaultValue={user.address?.complement}
                />
              </div>
              <div className="input-group">
                <h4>Bairro</h4>
                <Input
                  type="text"
                  ref={districtRef}
                  defaultValue={user.address?.district}
                />
              </div>
              <div className="input-group">
                <h4>Cidade</h4>
                <Input
                  type="text"
                  ref={cityRef}
                  defaultValue={user.address?.city}
                />
              </div>
              <div className="input-group">
                <h4>Estado</h4>
                <Select
                  ref={stateRef}
                  onChange={e => setState(e)}
                  defaultValue={user.address?.state}
                >
                  {states.map(stateObj => (
                    <Select.Option key={stateObj.key} value={stateObj.key}>
                      {stateObj.value}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          <Divider />

          <div className="button-group">
            <Button
              icon={<MdClose />}
              type="primary"
              danger
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
            <Button icon={<MdAdd />} htmlType="submit" type="primary">
              {isUpdating ? 'Atualizar' : 'Adicionar'}
            </Button>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default UsersForm;
