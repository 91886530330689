import { Button, Card, Input, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import api from '../../services/api';

import { Container } from './styles';

interface Subcategory {
  _id: string;
  name: string;
  description: string;
  category: {
    name: string;
  };
}

const Subcategories: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);

  const history = useHistory();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get('subcategories');

        setSubcategories(response.data);
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Usuários">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Subcategorias">
      <Container>
        <h2>Gerenciamento de Subcategorias</h2>

        <div id="controls">
          <Button
            icon={<MdAdd />}
            size="large"
            onClick={() => history.push('/subcategories/add')}
          >
            Adicionar
          </Button>

          <Input.Search
            placeholder="Pesquisar"
            size="large"
            style={{ maxWidth: 290 }}
          />
        </div>

        <div className="category-grid">
          {subcategories.map(subcategory => (
            <Link
              key={subcategory._id}
              to={`/subcategories/${subcategory._id}`}
            >
              <Card
                className="card"
                style={{ width: 300 }}
                title={subcategory.name}
                extra={subcategory.category.name}
              >
                <p>{subcategory.description}</p>
              </Card>
            </Link>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Subcategories;
