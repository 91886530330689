import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: #dbe5ff;
`;

export const Menu = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContentPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  margin-left: 20px;
  border-radius: 6px 0 0 0;
  max-height: 90vh;
  overflow-y: auto;

  padding: 10px 20px;
  padding-bottom: 30px;
`;
