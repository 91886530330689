import {
  Button,
  Card,
  Divider,
  Input,
  List,
  message,
  Modal,
  Select,
  Spin,
  TimePicker,
  Switch,
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { CgGym } from 'react-icons/cg';
import { MdAdd, MdCheck, MdClose, MdEdit, MdFilterList } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import api from '../../../services/api';
import { getYouTubeId } from '../../../utils/getVideoID';
import weekDays from '../../../utils/weekDays';

import { AddForm, Container } from './styles';

interface Category {
  _id: string;
  name: string;
}

interface Subcategory {
  _id: string;
  name: string;
  category: string;
}

interface Exercise {
  _id: string;
  name: string;
  description: string;
  status?: boolean;

  series: number;
  repetitions: number;
  interval: string;
  duration: string;
  load: number;
  alternativeExercise: string;

  video: {
    videoUrl: string;
  };
  category: string;
  subcategory: string;
  selected?: boolean;
  onEdit?: boolean;
}

interface SelectedExercises {
  _id: string;
  name: string;
  description: string;

  series: number;
  repetitions: number;
  interval: string;
  duration: string;
  load: number;
  alternativeExercise: {
    _id: string;
    name: string;
    description: string;

    series: number;
    repetitions: number;
    interval: string;
    duration: string;
    load: number;
    video: {
      videoUrl: string;
    };
    category: string;
    subcategory: string;
  };

  video: {
    videoUrl: string;
  };
  category: string;
  subcategory: string;
  selected?: boolean;
  onEdit?: boolean;
}

interface Training {
  _id: string;
  name: string;
  description: string;
  gender: string;
  segment: string;
  daysInWeek: string[];
}

const TrainingForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [training, setTraining] = useState<Training>({} as Training);
  const [searchCategory, setSearchCategory] = useState('');
  const [searchSubcategory, setSearchSubcategory] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [searchExercises, setSearchExercises] = useState<Exercise[]>([]);
  const [selectedExercises, setSelectedExercises] = useState<Exercise[]>([]);
  const [exercises, setExercises] = useState<SelectedExercises[]>([]);
  const [genderValue, setGenderValue] = useState('');
  const [segment, setSegment] = useState('');
  const [status, setStatus] = useState(false);

  const nameRef = useRef<Input>(null);
  const descriptionRef = useRef<TextArea>(null);
  const searchExerciseNameRef = useRef<Input>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('categories');

        setCategories(response.data);

        const subcategoriesResponse = await api.get('subcategories');

        setSubcategories(subcategoriesResponse.data);

        if (id !== 'add') {
          setIsUpdating(true);

          const trainingResponse = await api.get(`training/${id}`);
          setTraining(trainingResponse.data);
          setGenderValue(trainingResponse.data.gender);
          setSegment(trainingResponse.data.segment);
          setSelectedWeekDays(trainingResponse.data.daysInWeek);
          setExercises(trainingResponse.data.exercises);
          setStatus(trainingResponse.data.status);
        }
      } catch (err) {
        message.error('Falha ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id]);

  const handleAddTraining = (): void => {
    api
      .post('training', {
        name: nameRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        daysInWeek: selectedWeekDays,
        gender: genderValue,
        segment,
        exercises,
        status,
      })
      .then(() => {
        message.success('Treino criado com sucesso');
        history.push('/training');
      })
      .catch(() => message.error('Erro ao criar treino'))
      .finally(() => setLoading(false));
  };

  const handleUpdateTraining = (): void => {
    api
      .put(`training/${id}`, {
        name: nameRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        daysInWeek: selectedWeekDays,
        gender: genderValue,
        segment,
        exercises,
        status,
      })
      .then(() => {
        message.success('Treino atualizado com sucesso');
        history.push('/training');
      })
      .catch(() => message.error('Erro ao atualizar treino'))
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    if (exercises.length <= 0) {
      message.warning('Selecione pelo menos um exercício');
      return;
    }

    if (isUpdating) {
      handleUpdateTraining();
    } else {
      handleAddTraining();
    }
  };

  const handleLoadSubCategories = async (categoryId: string): Promise<void> => {
    const response = await api.get(`/subcategories-category/${categoryId}`);

    setSubcategories(response.data);
  };

  const handleChangeSearchCategory = async (value: string): Promise<void> => {
    setSearchCategory(value);

    await handleLoadSubCategories(value);
  };

  const handleSearchExercises = async (): Promise<void> => {
    const exercisesResponse = await api.get('exercises-search', {
      params: {
        name: searchExerciseNameRef.current?.input.value,
        categoryId: searchCategory,
        subcategoryId: searchSubcategory,
      },
    });

    setSearchExercises(exercisesResponse.data);
    setIsModalVisible(false);
  };

  const handleSelectExercise = (selectedExercise: Exercise): void => {
    const newSearchExercises = searchExercises.map(exercise => {
      if (String(exercise._id) === String(selectedExercise._id)) {
        if (exercise.selected) {
          setSelectedExercises(prev =>
            prev.filter(s => String(s._id) !== String(exercise._id)),
          );
          return { ...exercise, selected: false };
        }
        return { ...exercise, selected: true };
      }
      return exercise;
    });

    setSearchExercises(newSearchExercises);
    const selected = newSearchExercises.find(
      exercise => String(exercise._id) === String(selectedExercise._id),
    );

    if (selected?.selected) {
      setSelectedExercises(prev => [...prev, selectedExercise]);
    }
  };

  const handleChooseExercises = async (): Promise<any> => {
    // eslint-disable-next-line prefer-const
    let exercisesData = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selectedExercises.length; i++) {
      let alternativeExerciseData = {};

      // eslint-disable-next-line no-await-in-loop
      alternativeExerciseData = await handleSearchAlternativeExercise(
        selectedExercises[i].alternativeExercise,
      );

      exercisesData.push({
        _id: selectedExercises[i]._id,
        name: selectedExercises[i].name,
        description: selectedExercises[i].description,
        series: selectedExercises[i].series,
        repetitions: selectedExercises[i].repetitions,
        interval: selectedExercises[i].interval,
        duration: selectedExercises[i].duration,
        load: selectedExercises[i].load,
        alternativeExercise:
          selectedExercises[i].alternativeExercise !== ''
            ? alternativeExerciseData
            : '',
        video: {
          videoUrl: selectedExercises[i].video.videoUrl,
        },
        category: selectedExercises[i].category,
        subcategory: selectedExercises[i].subcategory,
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setExercises(exercisesData);
    setSelectedExercises([]);
    setSearchExercises([]);
  };

  async function handleSearchAlternativeExercise(
    idAlternativeExercise: string,
  ) {
    // idAlternativeExercise = id de um exercicio qualquer
    if (idAlternativeExercise === undefined || idAlternativeExercise === '') {
      return {};
    }

    const response = await api.get(`exercises/${idAlternativeExercise}`);

    return response.data;
  }

  const handleEditExercise = (exerciseId: string): void => {
    const newExercises = exercises.map(exercise => {
      if (String(exercise._id) === String(exerciseId)) {
        return { ...exercise, onEdit: !exercise.onEdit };
      }

      return exercise;
    });

    setExercises(newExercises);
  };

  const handleCheckExercise = (exerciseId: string): void => {
    const newExercises = exercises.map(exercise => {
      if (String(exercise._id) === String(exerciseId)) {
        return { ...exercise, onEdit: !exercise.onEdit };
      }

      return exercise;
    });

    setExercises(newExercises);
  };

  // const handleUpdateTime = (
  //   type: 'duration' | 'interval',
  //   index: number,
  //   time: string,
  // ): void => {
  //   const newExercises = exercises;
  //   newExercises[index][type] = time;

  //   setExercises(newExercises);
  // };

  const handleChangeExercises = (
    e: any,
    index: number,
    alternativeExercise?: boolean,
  ) => {
    const { name, value } = e.target;
    if (alternativeExercise) {
      const newExercises = exercises;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newExercises[index].alternativeExercise[name] = value;
      setExercises(newExercises);
    } else {
      const newExercises = exercises;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newExercises[index][name] = value;
      setExercises(newExercises);
    }
  };

  if (loading) {
    return (
      <Layout title="Treinos">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Treinos">
      <Container>
        <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>

        <AddForm onSubmit={handleSubmit}>
          <div className="input-group">
            <h4>Nome</h4>
            <Input
              ref={nameRef}
              defaultValue={training.name}
              type="text"
              required
            />
          </div>

          <div className="input-group">
            <h4>Instruções/Descrição</h4>
            <TextArea
              ref={descriptionRef}
              defaultValue={training.description}
              rows={3}
              required
            />
          </div>

          <div className="input-group">
            <h4>Dias da semana</h4>
            <Select
              mode="multiple"
              allowClear
              placeholder="Selecione os dias"
              value={selectedWeekDays}
              onChange={(_, data) => {
                setSelectedWeekDays(
                  data.map((day: { value: string }) => day.value),
                );
              }}
            >
              {weekDays.map(day => (
                <Select.Option value={day.key} key={day.key}>
                  {day.value}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="input-group">
            <h4>Gênero</h4>
            <Select onChange={e => setGenderValue(e)} value={genderValue}>
              <Select.Option value="M">Masculino</Select.Option>
              <Select.Option value="F">Feminino</Select.Option>
            </Select>
          </div>

          <div className="input-group">
            <h4>Segmento de Treino</h4>
            <Select onChange={e => setSegment(e)} value={segment}>
              <Select.Option value="condominio">Condomínio</Select.Option>
              <Select.Option value="corporativo">Corporativo</Select.Option>
              <Select.Option value="personal">Persoanl Trainer</Select.Option>
              <Select.Option value="amador">Praticante Amador</Select.Option>
            </Select>
          </div>

          <h4>Status</h4>
          <Switch
            onChange={(e: any) => {
              setStatus(e);
            }}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={status}
          />

          <div className="form-group">
            <div>
              <h3>Exercícios</h3>
              <Button
                icon={<MdFilterList />}
                onClick={() => setIsModalVisible(true)}
              >
                Filtros
              </Button>
            </div>
            <Modal
              // eslint-disable-next-line prettier/prettier
              title={
                <>
                  <h3>Pesquisar exercícios</h3>
                  <span>Deixe em branco para ver todos</span>
                </>
                // eslint-disable-next-line prettier/prettier
              }
              centered
              visible={isModalVisible}
              onCancel={() => {
                setIsModalVisible(false);
                setSearchCategory('');
                setSearchSubcategory('');
              }}
              footer={[
                <Button
                  key="back"
                  onClick={() => {
                    setIsModalVisible(false);
                    setSearchCategory('');
                    setSearchSubcategory('');
                  }}
                >
                  Cancelar
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleSearchExercises}
                >
                  Aplicar
                </Button>,
              ]}
            >
              <div className="inputs-group">
                <div className="input-group">
                  <h4>Nome</h4>
                  <Input ref={searchExerciseNameRef} type="text" />
                </div>
                <div className="input-group">
                  <h4>Categoria</h4>
                  <Select
                    style={{ width: '100%' }}
                    value={searchCategory}
                    onChange={handleChangeSearchCategory}
                  >
                    {categories.map(categoryItem => (
                      <Select.Option
                        key={categoryItem._id}
                        value={categoryItem._id}
                      >
                        {categoryItem.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="input-group">
                  <h4>Subcategoria</h4>
                  <Select
                    style={{ width: '100%' }}
                    value={searchSubcategory}
                    onChange={e => setSearchSubcategory(e)}
                  >
                    {subcategories.map(subcategoryItem => (
                      <Select.Option
                        key={subcategoryItem._id}
                        value={subcategoryItem._id}
                      >
                        {subcategoryItem.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Modal>
            {exercises.length > 0 && (
              <div style={{ marginTop: 20 }}>
                <strong>Selecionados:</strong>
                <List
                  style={{ marginTop: 5 }}
                  grid={{ gutter: 20 }}
                  dataSource={exercises}
                  renderItem={(item, index) => (
                    <List.Item>
                      <Card
                        cover={
                          item.video?.videoUrl ? (
                            <img
                              alt={item.name}
                              src={`https://img.youtube.com/vi/${getYouTubeId(
                                item.video.videoUrl,
                              )}/0.jpg`}
                            />
                          ) : (
                            <CgGym
                              style={{
                                height: item.onEdit ? 'auto' : 150,
                                maxHeight: 220,
                              }}
                              color="#a6a6a6"
                            />
                          )
                        }
                        style={{
                          width: item.onEdit ? 300 : 200,
                          border: '4px solid #3369cc',
                        }}
                        actions={[
                          item.onEdit ? (
                            <MdCheck
                              onClick={() => handleCheckExercise(item._id)}
                            />
                          ) : (
                            <MdEdit
                              onClick={() => handleEditExercise(item._id)}
                            />
                          ),
                        ]}
                      >
                        {item.onEdit ? (
                          <>
                            {' '}
                            <div className="inputs-group">
                              <div className="input-group">
                                <h4>Séries</h4>
                                <Input
                                  defaultValue={item.series}
                                  type="numbers"
                                  name="series"
                                  onChange={(e: any) => {
                                    handleChangeExercises(e, index);
                                  }}
                                />
                              </div>
                              <div className="input-group">
                                <h4>Repetições</h4>
                                <Input
                                  defaultValue={item.repetitions}
                                  type="numbers"
                                  name="repetitions"
                                  onChange={(e: any) => {
                                    handleChangeExercises(e, index);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="inputs-groups">
                              <div className="input-group">
                                <h4>Intervalo/Descanso</h4>
                                <TimePicker
                                  name="interval"
                                  defaultValue={moment(
                                    item.interval,
                                    'HH:mm:ss',
                                  )}
                                  onChange={(_, timeString) => {
                                    handleChangeExercises(
                                      {
                                        target: {
                                          name: 'interval',
                                          value: timeString,
                                        },
                                      },
                                      index,
                                    );
                                  }}
                                  placeholder="Selecione tempo"
                                />
                              </div>
                              <div className="input-group">
                                <h4>Duração</h4>
                                <TimePicker
                                  name="duration"
                                  defaultValue={moment(
                                    item.duration,
                                    'HH:mm:ss',
                                  )}
                                  onChange={(_, timeString) => {
                                    handleChangeExercises(
                                      {
                                        target: {
                                          name: 'duration',
                                          value: timeString,
                                        },
                                      },
                                      index,
                                    );
                                  }}
                                  placeholder="Selecione tempo"
                                />
                              </div>
                            </div>
                            <div className="input-group">
                              <h4>Carga</h4>
                              <Input
                                type="number"
                                name="load"
                                onChange={(e: any) => {
                                  handleChangeExercises(e, index);
                                }}
                                defaultValue={item.load}
                                suffix={<span>Kg</span>}
                                required
                              />
                            </div>
                            {item.alternativeExercise.name && (
                              <>
                                <Divider />
                                <div className="inputs-group">
                                  <div className="input-group">
                                    <h4>Exercício Alternativo</h4>
                                  </div>
                                </div>

                                <div className="inputs-group">
                                  <div className="input-group">
                                    <h4>{item.alternativeExercise.name}</h4>
                                  </div>
                                </div>
                                <div className="inputs-group">
                                  <div className="input-group">
                                    <h4>Séries</h4>
                                    <Input
                                      defaultValue={
                                        item.alternativeExercise.series
                                      }
                                      type="numbers"
                                      name="series"
                                      onChange={(e: any) => {
                                        handleChangeExercises(e, index, true);
                                      }}
                                    />
                                  </div>
                                  <div className="input-group">
                                    <h4>Repetições</h4>
                                    <Input
                                      defaultValue={
                                        item.alternativeExercise.repetitions
                                      }
                                      type="numbers"
                                      name="repetitions"
                                      onChange={(e: any) => {
                                        handleChangeExercises(e, index, true);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="inputs-groups">
                                  <div className="input-group">
                                    <h4>Intervalo/Descanso</h4>
                                    <TimePicker
                                      name="interval"
                                      defaultValue={moment(
                                        item.alternativeExercise.interval,
                                        'HH:mm:ss',
                                      )}
                                      onChange={(_, timeString) => {
                                        handleChangeExercises(
                                          {
                                            target: {
                                              name: 'interval',
                                              value: timeString,
                                            },
                                          },
                                          index,
                                          true,
                                        );
                                      }}
                                      placeholder="Selecione tempo"
                                    />
                                  </div>
                                  <div className="input-group">
                                    <h4>Duração</h4>
                                    <TimePicker
                                      name="duration"
                                      defaultValue={moment(
                                        item.alternativeExercise.duration,
                                        'HH:mm:ss',
                                      )}
                                      onChange={(_, timeString) => {
                                        handleChangeExercises(
                                          {
                                            target: {
                                              name: 'duration',
                                              value: timeString,
                                            },
                                          },
                                          index,
                                          true,
                                        );
                                      }}
                                      placeholder="Selecione tempo"
                                    />
                                  </div>
                                </div>
                                <div className="input-group">
                                  <h4>Carga</h4>
                                  <Input
                                    name="load"
                                    type="number"
                                    onChange={(e: any) => {
                                      handleChangeExercises(e, index, true);
                                    }}
                                    defaultValue={item.alternativeExercise.load}
                                    suffix={<span>Kg</span>}
                                    required
                                  />
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <Card.Meta title={item.name} />
                        )}
                      </Card>
                    </List.Item>
                  )}
                />
              </div>
            )}
            {searchExercises.length > 0 && (
              <>
                <strong>Escolha:</strong>
                <List
                  style={{ marginTop: 20 }}
                  grid={{ gutter: 20 }}
                  dataSource={searchExercises}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        onClick={() => handleSelectExercise(item)}
                        cover={
                          item.video?.videoUrl ? (
                            <img
                              alt={item.name}
                              src={`https://img.youtube.com/vi/${getYouTubeId(
                                item.video.videoUrl,
                              )}/0.jpg`}
                            />
                          ) : (
                            <CgGym style={{ height: 150 }} color="#a6a6a6" />
                          )
                        }
                        style={{
                          width: 200,
                          border: item.selected ? '4px solid #3369cc' : '',
                        }}
                        hoverable
                      >
                        <Card.Meta title={item.name} />
                      </Card>
                    </List.Item>
                  )}
                />

                <Button type="default" onClick={handleChooseExercises}>
                  Pronto
                </Button>
              </>
            )}
          </div>

          <Divider />

          <div className="button-group">
            <Button
              icon={<MdClose />}
              type="primary"
              danger
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
            <Button icon={<MdAdd />} type="primary" htmlType="submit">
              {isUpdating ? 'Atualizar' : 'Adicionar'}
            </Button>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default TrainingForm;
