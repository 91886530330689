import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Categories from '../pages/Categories';
import CategoriesForm from '../pages/Categories/CategoriesForm';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/forgotPassword';
import Training from '../pages/Training';
import Users from '../pages/Users';
import UsersForm from '../pages/Users/UsersForm';
import Videos from '../pages/Videos';
import VideoForm from '../pages/Videos/VideoForm';
import Subcategories from '../pages/Subcategories';
import SubcategoriesForm from '../pages/Subcategories/SubcategoriesForm';
import Entities from '../pages/Entities';
import EntitiesForm from '../pages/Entities/EntitiesForm';
import TrainingForm from '../pages/Training/TrainingForm';
import Exercises from '../pages/Exercises';
import ExercisesForm from '../pages/Exercises/ExercisesForm';
import Equipments from '../pages/Equipments';
import EquipmentForm from '../pages/Equipments/EquipmentForm';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/sign-up" component={SignUp} />

    <Route path="/forgot-password" component={ForgotPassword} />

    <Route path="/dashboard" isPrivate component={Dashboard} />

    <Route path="/users" exact isPrivate component={Users} />
    <Route path="/users/:id" isPrivate component={UsersForm} />

    <Route path="/entities" exact isPrivate component={Entities} />
    <Route path="/entities/:id" isPrivate component={EntitiesForm} />

    <Route path="/categories" exact isPrivate component={Categories} />
    <Route path="/categories/:id" isPrivate component={CategoriesForm} />

    <Route path="/subcategories" exact isPrivate component={Subcategories} />
    <Route
      path="/subcategories/:id"
      exact
      isPrivate
      component={SubcategoriesForm}
    />

    <Route path="/videos" exact isPrivate component={Videos} />
    <Route path="/videos/:id" isPrivate component={VideoForm} />

    <Route path="/exercises" exact isPrivate component={Exercises} />
    <Route path="/exercises/:id" exact isPrivate component={ExercisesForm} />

    <Route path="/equipments" exact isPrivate component={Equipments} />
    <Route path="/equipments/:id" exact isPrivate component={EquipmentForm} />

    <Route path="/training" exact isPrivate component={Training} />
    <Route path="/training/:id" isPrivate component={TrainingForm} />

    <Route component={NotFound} />
  </Switch>
);

export default Routes;
