import { Button, Card, Input, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { CgGym } from 'react-icons/cg';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import api from '../../services/api';
import { getYouTubeId } from '../../utils/getVideoID';

import { Container } from './styles';

interface Equipment {
  _id: string;
  name: string;
  description: string;
  video?: {
    _id: string;
    videoUrl: string;
  };
}

const Equipments: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [equipmentsList, setEquipmentsList] = useState<Equipment[]>([]);

  const history = useHistory();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get('equipments');

        setEquipments(response.data);
        setEquipmentsList(response.data);
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Equipamentos">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  function handleSearchEquipments(value: string) {
    const search = equipments.filter(
      (i: Equipment) => i.name.toUpperCase().indexOf(value.toUpperCase()) > -1,
    );
    setEquipmentsList(search);
  }

  return (
    <Layout title="Equipamentos">
      <Container>
        <h2>Gerenciamento de Equipamentos</h2>

        <div id="controls">
          <Button
            icon={<MdAdd />}
            size="large"
            onClick={() => history.push('/equipments/add')}
          >
            Adicionar
          </Button>

          <Input
            placeholder="Pesquisar"
            size="large"
            style={{ maxWidth: 290 }}
            onChange={(e: any) => handleSearchEquipments(e.target.value)}
          />
        </div>

        <div className="category-grid">
          {equipmentsList.map(equipment => (
            <Card
              onClick={() => history.push(`equipments/${equipment._id}`)}
              hoverable
              style={{ width: 220 }}
              cover={
                equipment.video?.videoUrl ? (
                  <img
                    alt={equipment.name}
                    src={`https://img.youtube.com/vi/${getYouTubeId(
                      equipment.video.videoUrl,
                    )}/0.jpg`}
                  />
                ) : (
                  <CgGym size={164} color="#a6a6a6" />
                )
              }
            >
              <Card.Meta title={equipment.name} />
            </Card>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Equipments;
