import { Button, Card, Input, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { CgGym } from 'react-icons/cg';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import api from '../../services/api';
import { getYouTubeId } from '../../utils/getVideoID';

import { Container } from './styles';

interface Exercise {
  _id: string;
  name: string;
  description: string;
  video?: {
    _id: string;
    videoUrl: string;
  };
}

const Exercises: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [exercisesList, setExercisesList] = useState<Exercise[]>([]);

  const history = useHistory();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get('exercises');

        setExercises(response.data);
        setExercisesList(response.data);
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Exercícios">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  function handleSearchExercicies(value: string) {
    const search = exercises.filter(
      (i: Exercise) => i.name.toUpperCase().indexOf(value.toUpperCase()) > -1,
    );
    setExercisesList(search);
  }

  return (
    <Layout title="Exercícios">
      <Container>
        <h2>Gerenciamento de Exercícios</h2>

        <div id="controls">
          <Button
            icon={<MdAdd />}
            size="large"
            onClick={() => history.push('/exercises/add')}
          >
            Adicionar
          </Button>

          <Input
            placeholder="Pesquisar"
            size="large"
            style={{ maxWidth: 290 }}
            onChange={(e: any) => handleSearchExercicies(e.target.value)}
          />
        </div>

        <div className="category-grid">
          {exercisesList.map(exercise => (
            <Card
              onClick={() => history.push(`exercises/${exercise._id}`)}
              hoverable
              style={{ width: 220 }}
              cover={
                exercise.video?.videoUrl ? (
                  <img
                    alt={exercise.name}
                    src={`https://img.youtube.com/vi/${getYouTubeId(
                      exercise.video.videoUrl,
                    )}/0.jpg`}
                  />
                ) : (
                  <CgGym size={164} color="#a6a6a6" />
                )
              }
            >
              <Card.Meta title={exercise.name} />
            </Card>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Exercises;
