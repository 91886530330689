import styled from 'styled-components';

export const Container = styled.div`
  > h2 {
    margin-bottom: 20px;
  }
`;

export const AddForm = styled.form`
  .input-group {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
  }

  .inputs-group {
    display: flex;
    gap: 16px;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .button-deletar {
      margin-top: 20px;
    }

    .button-group {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      button {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
  }
`;
